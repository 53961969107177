<template>
  <div v-cloak>
    <div v-if="apiLoaded">
      <transition appear appear-active-class="fade-enter-active" name="fade">
        <div class="wrap contest">
          <router-link :to="'/event/info/'+$route.params.configId" class="back-link">
            <v-icon>mdi-chevron-left</v-icon>
            Назад
          </router-link>
          <h1>Отчет о результатах направления заявок
            <div class="download-menu">
              <v-menu top>
                <template v-slot:activator="{ on }">
                  <div class="download-menu-icon" v-on="on">
                    <svg
                        fill="none"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M8 17V15H16V17H8ZM16 10L12 14L8 10H10.5V7H13.5V10H16ZM5 3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3ZM5 5V19H19V5H5Z"
                          fill="#0033A0"
                      />
                    </svg>
                  </div>
                </template>

                <v-list>
                  <v-list-item @click.prevent="getReport($route.params.configId)">
                    <v-list-item-title>Скачать XLSX</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click.prevent="pdfExport()">
                    <v-list-item-title>Скачать PDF</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <span>В конкурсе «{{ config.event.title }}»</span>
          </h1>

          <v-tabs>
            <v-tab v-for="(nominationTitle, nominationTitleIndex) in config.eventJson.nominations"
                   :key="'nominationTitleIndex' + nominationTitleIndex">
              {{nominationTitle.name}}
            </v-tab>
            <v-tab-item v-for="(nomination, nominationIndex) in config.eventJson.nominations" :key="'nominationIndex' + nominationIndex">
              <table class="regions-table" cellpadding="0" cellspacing="0">
                <thead>
                <tr>
                  <th>Регион</th>
                  <th v-for="(field, fieldIndex) in nomination.fields" :key="fieldIndex+'invIndex'">{{field.name}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(region, regionIndex) in regionsList" :key="'regionIndex' + regionIndex">
                  <th>{{ region.name }}</th>
                  <template v-if="regionObjectsArray[nominationIndex][regionIndex] = findRegionObjects(filesTableArray[nominationIndex], region.name)">
                    <span v-show="false">{{ fieldObjectArray[nominationIndex][regionIndex] = [] }}</span><!-- объявление внутреннего массива, без этого не работает-->
                    <td v-for="(field, fieldIndex) in nomination.fields" :key="fieldIndex+'invIndex'">
                      <template v-if="fieldObjectArray[nominationIndex][regionIndex][fieldIndex] = findFieldObject(regionObjectsArray[nominationIndex][regionIndex], field.fieldName)">
                        <template v-if="field.type === 'FILE'">
                          <a @click="downloadFile(region.name,
                          nomination.nominationName,
                          fieldObjectArray[nominationIndex][regionIndex][fieldIndex].fieldName,
                          fieldObjectArray[nominationIndex][regionIndex][fieldIndex].originalName)">
                            {{ truncateStr(fieldObjectArray[nominationIndex][regionIndex][fieldIndex].originalName, 10)}}
                          </a>
                        </template>
                        <template v-else-if="field.type === 'LINK'">
                          <a :href="fieldObjectArray[nominationIndex][regionIndex][fieldIndex].name" target="_blank">
                            {{ truncateStr(fieldObjectArray[nominationIndex][regionIndex][fieldIndex].name, 10 )}}
                          </a>
                        </template>
                        <template v-else-if="field.type === 'TEXT'">
                          <div>{{ fieldObjectArray[nominationIndex][regionIndex][fieldIndex].name }}</div>
                        </template>
                      </template>
                      <template v-else>
                        —
                      </template>
                    </td>
                  </template>
                </tr>
                </tbody>
              </table>
            </v-tab-item>
          </v-tabs>
        </div>
      </transition>
    </div>
    <div v-else style="height:2000px"></div>
  </div>
</template>

<script>
import api from "@/modules/api";

export default {
  name: "EventRegionsInfoComponent",
  props: {},
  data() {
    return {
      apiLoaded: false,
      config: null,
      regionsList: [],
      filesTableArray: [],
      regionObjectsArray: [],
      fieldObjectArray: [],
      controllerRoles: ['CONTEST_CONTROLLER', 'ROLE_ADMIN'],
    }
  },

  methods: {
    async loadFilesTable(nomination, configId) {
      let req = await api.get("/event/getTable", {
        nomination: nomination,
        configId: configId,
      });
      if (req.ok) {
        return req.payload;
      } else {
        console.log('get error');
      }
    },

    async downloadFile(region, nomination, fieldName, originalFileName) {
      let req = await api.download("/event/get", {
        region: region,
        nomination: nomination,
        fieldName: fieldName,
      });
      if (req.ok) {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(req.payload);
        link.download = originalFileName;
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        console.log('download error');
      }
    },

    async getReport(configId) {
      let req = await api.download("event/report/getReport", {
        configId: configId
      });
      if (req.ok) {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(req.payload);
        link.download = "Отчет о результатах направления заявок - " + this.config.event.title.substring(0, 50).replaceAll(".", "_");
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        console.log('download error');
      }
    },

    findRegionObjects(filesTable, region) {
      let regionObjects = [];
      for (let i = 0; i < filesTable.length; i++) {
        if (filesTable[i].region === region) {
          regionObjects.push(filesTable[i])
        }
      }
      return regionObjects
    },

    findFieldObject(regionObjects, fieldName) {
      for (let i = 0; i < regionObjects.length; i++) {
        if (regionObjects[i].fieldName === fieldName) {
          return regionObjects[i]
        }
      }
    },

    truncateStr(str, n) {
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 7, str.length)
      }
      return str
    },

    //скачивает конфигурацию с бэка
    async loadConfig(id) {
      let req = await this.$getApi("/event/config/getConfig?id=" + id)
      if (req.ok) {
        this.config = req.payload
        this.config.eventJson = JSON.parse(this.config.eventJson)
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    checkRoles(userRoles) {
      for (let role of userRoles) {
        if (this.$user.subroles.includes(role)) {
          return true;
        }
      }
      return false;
    },

    async pdfExport() {
      let headerCells = [];
      let regionObjects = [];
      let fieldObjects = [];
      let rows = [];
      for (let nominationIndex = 0; nominationIndex < this.config.eventJson.nominations.length; nominationIndex++) {
        let titles = [{caption: "Регион" , width: 4000}];

        let fields = this.config.eventJson.nominations[nominationIndex].fields;

        titles = titles.concat(fields.map((field) => {return {caption: field.name, width: 4000}}));
        headerCells.push(titles);

        regionObjects.push([]);
        fieldObjects.push([]);
        rows.push([]);

        let nominationRow = fields.map(() => {return {cellDataType: "STRING", value: ""}})

        nominationRow.unshift({cellDataType: "STRING", value: this.config.eventJson.nominations[nominationIndex].name})
        rows[nominationIndex].push(nominationRow);

        for (let regionIndex = 0; regionIndex < this.regionsList.length; regionIndex++) {

          rows[nominationIndex].push([{cellDataType: "STRING", value: this.regionsList[regionIndex].name}])

          let value = "Нет";

          if (this.findRegionObjects(this.filesTableArray[nominationIndex], this.regionsList[regionIndex].name)) {

            regionObjects[nominationIndex][regionIndex] = this.findRegionObjects(this.filesTableArray[nominationIndex], this.regionsList[regionIndex].name)
            fieldObjects[nominationIndex][regionIndex] = [];

            for (let fieldIndex = 0; fieldIndex < fields.length; fieldIndex++) {

              if (this.findFieldObject(regionObjects[nominationIndex][regionIndex], fields[fieldIndex].fieldName)) {

                fieldObjects[nominationIndex][regionIndex][fieldIndex] = this.findFieldObject(regionObjects[nominationIndex][regionIndex], fields[fieldIndex].fieldName)

                if ((fields[fieldIndex].type === 'FILE' && fieldObjects[nominationIndex][regionIndex][fieldIndex].originalName != null)
                    || (fields[fieldIndex].type === 'LINK' && fieldObjects[nominationIndex][regionIndex][fieldIndex].name != null)
                    || (fields[fieldIndex].type === 'TEXT' && fieldObjects[nominationIndex][regionIndex][fieldIndex].name != null)) {

                  value = "Да";
                }
              }
              rows[nominationIndex][regionIndex + 1].push({cellDataType: "STRING", value: value})
            }
          }
        }
      }
      let query = {
        headerCells: headerCells,
        rows: rows,
      };

      await this.$downloadApi("Отчет о результатах направления заявок - " + this.config.event.title.substring(0, 50).replaceAll(".", "_"),"/event/report/getPDFReport", query, true);
    },
  },

  filters: {
    dateTimeFormat: function (date) {
      if (date == null) {
        return ''
      }
      const mdate = new Date(date)
      const options = {
        timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      return mdate.toLocaleDateString('ru-RU', options)
    },
  },

  async beforeMount() {
    if (this.checkRoles(this.controllerRoles)) {
      await this.loadConfig(this.$route.params.configId);
      this.regionsList = this.config.event.contestRegions;
      this.regionsList.sort((a, b) => (a.name > b.name ? 1 : -1));
      for (let i = 0; i < this.config.eventJson.nominations.length; i++) {
        this.filesTableArray.push(await this.loadFilesTable(this.config.eventJson.nominations[i].nominationName, this.$route.params.configId));
        this.regionObjectsArray.push([]);
        this.fieldObjectArray.push([]);
      }
      this.apiLoaded = true;
    }
    // if (this.$user.subroles.includes('ROLE_ADMIN') || this.$user.subroles.includes('ROLE_VNII')) {
    //   this.spec_filesTable = await this.loadFilesTable('specialist');
    //   this.czn_filesTable = await this.loadFilesTable('czn');
    //   this.proj_filesTable = await this.loadFilesTable('project');
    //   this.apiLoaded = true;
    // }
  },
}

</script>

<style lang="scss">
@import "../styles/main.scss";
.contest .v-tabs-bar {
  margin-bottom: 60px !important;
}
</style>

<style lang="scss" scoped>
.header-select {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #777779;
    width: 626px;
    max-width: 100%;
  }

  .v-btn {
    margin-left: auto;
    height: 56px;
  }
}

.v-icon {
  color: #0033a0;
}

.v-tabs-items .v-window-item, .v-window__container, .v-item-group {
  overflow: unset !important;
}
.regions-table {
  width: 100%;
  overflow: unset;
  thead {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
    th {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 160%;
      color: #777779;
      border-bottom: 1px solid #E8E8E9;
      position: sticky;
      top: 0;
      &:first-child {
        text-align: left;
      }
    }
  }
  tbody {
    th {
      font-size: 12px;
      font-weight: normal;
      text-align: left;
      //white-space: nowrap;
      //text-align: center;
      border-bottom: 1px solid #E8E8E9;
      padding-right: 20px;
    }
    td {
      border-bottom: 1px solid #E8E8E9;
      padding: 20px 5px;
      font-size: 12px;
      text-align: center;

    }

  }

}

</style>
